<template>
  <v-dialog
    :retain-focus="false"
    v-model="modalIsShow"
    width="700"
    @click:outside="closeModal"
  >
    <v-card>
      <v-tabs
        v-model="tab"
        background-color="primary"
      >
        <v-tab key="description">Опис</v-tab>
        <v-tab key="apartments">Нерухомість</v-tab>
      </v-tabs>

      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item key="description">
            <v-container>
              <v-text-field
                label="Імʼя"
                v-model="item.name"
                :error-messages="updateErrors.name"
              ></v-text-field>

              <v-text-field
                label="Мінімальна ціна"
                prefix="$"
                v-model="item.filters.min_price"
                :error-messages="updateErrors.min_price"
              ></v-text-field>

              <v-text-field
                label="Максимальна ціна"
                prefix="$"
                v-model="item.filters.max_price"
                :error-messages="updateErrors.max_price"
              ></v-text-field>

              <v-select
                v-model="item.filters.categories"
                :items="categories"
                label="Категорії"
                :item-text="category"
                item-value="id"
                multiple
                persistent-hint
                :error-messages="updateErrors.categories"
              ></v-select>
            </v-container>

            <v-divider />

            <v-card-actions class="d-flex flex-wrap">
              <v-btn
                color="red"
                @click="deleteItem()"
              >
                Видалити клієнта
              </v-btn>
              <v-spacer />
              <v-btn
                color="default"
                @click="closeModal()"
              >
                Закрити
              </v-btn>
              <v-btn
                color="blue"
                @click="updateItem()"
              >
                Оновити
              </v-btn>
            </v-card-actions>
          </v-tab-item>
          <v-tab-item key="apartments">
            <v-list-item-group light v-for="group in item.grouped_apartments" :key="group.price_range" class="mb-4">
              <v-list-item-title class="font-weight-bold text-h5">
                {{ group.price_range }}
              </v-list-item-title>
              <v-list-item
                v-for="(child, i) in group.apartments"
                :key="`child_${child.title + i}`"
              >
                <v-checkbox class="pr-2" v-model="child.is_finished" @click="updateFinishApartment(child)"></v-checkbox>
                <v-list-item-title v-html="child.title" style="white-space: normal" :class="{crossed: child.is_crossed}">
                </v-list-item-title>
                <span style="color: red" v-if="child.is_new"> (*нове)</span>

                <v-icon
                  right
                  @click="updateCrossApartment(child)"
                >
                  mdi-check-underline
                </v-icon>
              </v-list-item>
            </v-list-item-group>

            <div class="py-3 mt-10" color="lightgray" style="opacity: 0.7">
              <v-card-title>Завершені</v-card-title>

              <v-list-item-group>
                <v-list-item
                  v-for="(child, i) in item.finished_apartments"
                  :key="`child_${child.title + i}`"
                >
                  <v-checkbox class="pr-2" v-model="child.is_finished" @click="updateFinishApartment(child)"></v-checkbox>
                  <v-list-item-title :class="{crossed: child.is_crossed}" v-html="child.title" style="white-space: normal">
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </div>

          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import category from "../../../services/category";
import client from "../../../services/client";
import clientApartment from "../../../services/clientApartment";

export default {
  name: 'ItemModal',
  props: {
    item: {
      type: Object,
      required: true
    },
    modalIsShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tab: 'description',
      categories: [],
      updateErrors: {
        name: '',
        min_price: [],
        max_price: [],
        categories: []
      }
    }
  },

  mounted() {
    this.getCategories()
  },

  methods: {
    closeModal() {
      this.$emit('closeModal')
    },

    getCategories() {
      category.get()
        .then((res) => {
          if (res) this.categories = res.data.data
        })
    },

    category: item => item.title + ' (' + item.catalog_title + ')',

    updateItem() {
      client.update(this.item)
        .then((res) => {
          if (res) this.$router.go(0);
        })
        .catch((err) => {
          if (err.status === 422) {
            const errors = err.data.errors;
            this.updateErrors.name = errors.name;
            this.updateErrors.min_price = errors['filters.min_price'];
            this.updateErrors.max_price = errors['filters.max_price'];
            this.updateErrors.categories = errors['filters.categories'];
          }
        })
    },

    updateCrossApartment(apartment) {
      apartment.is_crossed = !apartment.is_crossed

      clientApartment.updateApartmentCross(this.item.id, apartment)
    },

    updateFinishApartment(apartment) {
      clientApartment.updateApartmentFinish(this.item.id, apartment)
        .then(() => this.$router.go(0))
    },

    deleteItem() {
      client.delete(this.item.id)
        .then((res) => {
          if (res) this.$router.go(0);
        })
    }
  }
};
</script>

<style>
.crossed {
  text-decoration: line-through;
  opacity: 0.7;
}

.finished-list {
  background-color: lightgray;
  opacity: 0.8;
}
</style>
