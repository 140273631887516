import axios from "axios";

const clientApartment = {
  updateApartmentFinish: async function (clientId, apartment) {
    try {
      return await axios.put(`/api/dashboard/client/apartment/${clientId}/${apartment.id}/finish`, {is_finished: apartment.is_finished});

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  updateApartmentCross: async function (clientId, apartment) {
    try {
      return await axios.put(`/api/dashboard/client/apartment/${clientId}/${apartment.id}/cross`, {is_crossed: apartment.is_crossed});

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },
}

export default clientApartment;
