<template>
  <v-dialog
    :retain-focus="false"
    v-model="modalIsShow"
    width="700"
    @click:outside="closeModal"
  >
    <v-card>
      <v-card-title
        class="white--text primary"
        align="left"
      >
        Додати
      </v-card-title>
      <v-container>
        <v-text-field
          label="Імʼя"
          v-model="item.name"
          :error-messages="storeErrors.name"
        ></v-text-field>

        <v-text-field
          label="Мінімальна ціна"
          prefix="$"
          v-model="item.filters.min_price"
          :error-messages="storeErrors.min_price"
        ></v-text-field>

        <v-text-field
          label="Максимальна ціна"
          prefix="$"
          v-model="item.filters.max_price"
          :error-messages="storeErrors.max_price"
        ></v-text-field>

        <v-select
          v-model="item.filters.categories"
          :items="categories"
          label="Категорії"
          :item-text="category"
          item-value="id"
          multiple
          persistent-hint
          :error-messages="storeErrors.categories"
        ></v-select>
      </v-container>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="default"
          @click="closeModal()"
        >
          Закрити
        </v-btn>
        <v-btn
          color="blue"
          @click="storeClient()"
        >
          Зберегти
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import client from "../../../services/client";
import category from "../../../services/category";

export default {
  name: 'ItemModal',
  props: {
    modalIsShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      categories: [],
      item: {
        name: '',
        filters: {
          min_price: '',
          max_price: '',
          categories: []
        }
      },

      storeErrors: {
        name: '',
        min_price: [],
        max_price: [],
        categories: []
      }
    }
  },

  mounted() {
    this.getCategories()
  },

  methods: {
    closeModal() {
      this.$emit('closeModal')
    },

    getCategories() {
      category.get()
        .then((res) => {
          if (res) this.categories = res.data.data
        })
    },

    category: item => item.title + ' (' + item.catalog_title + ')',

    storeClient() {
      client.store(this.item)
        .then((res) => {
          if (res) this.$router.go(0);
        })
        .catch((err) => {
          if (err.status === 422) {
            const errors = err.data.errors;
            this.storeErrors.name = errors.name;
            this.storeErrors.min_price = errors['filters.min_price'];
            this.storeErrors.max_price = errors['filters.max_price'];
            this.storeErrors.categories = errors['filters.categories'];
          }
        })
    }
  }
};
</script>
