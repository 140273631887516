<script>
import NewItemModal from "./NewItemModal.vue";
import ItemModal from "./ItemModal.vue";
import client from "../../../services/client";

export default {
  components: {NewItemModal, ItemModal},

  data() {
    return {
      newItemModalIsShow: false,
      modalIsShow: false,
      tableData: [],
      currentItem: null
    }
  },

  mounted() {
    this.getTableData()
  },

  methods: {
    toggleNewItemModal() {
      this.newItemModalIsShow = !this.newItemModalIsShow;
    },

    toggleModal() {
      this.modalIsShow = !this.modalIsShow;
    },

    getTableData() {
      client.get()
        .then((res) => {
          if (res) this.tableData = res.data.data;
        })
    },

    selectItem(itemId) {
      client.show(itemId)
        .then((res) => {
          if (res) this.currentItem = res.data.data
          let itemIndex = this.tableData.findIndex(x => x.id === itemId)
          this.tableData[itemIndex].new_matches_count = 0
        })
      this.toggleModal()
    }
  }
}
</script>

<template>
  <v-container>
    <base-material-card
      color="indigo"
      icon="mdi-account-group"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Клієнти
        </div>
      </template>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="toggleNewItemModal()"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Додати
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="mt-3 mb-3"/>

      <v-list-item-group light>
        <v-list-item
          v-for="(child, i) in tableData"
          :key="`child_${child.title + i}`"
        >
          <v-list-item-title v-if="child.new_matches_count > 0">
            <v-badge inline color="red" :content="child.new_matches_count" class="ma-0">{{ child.name }}</v-badge>
          </v-list-item-title>
          <v-list-item-title v-else v-html="child.name" style="white-space: normal" />
          <v-icon
            right
            @click="selectItem(child.id)"
          >
            mdi-eye
          </v-icon>
        </v-list-item>
      </v-list-item-group>
    </base-material-card>

    <ItemModal
      :modal-is-show="modalIsShow"
      :item="currentItem"
      @closeModal="toggleModal"
      v-if="currentItem"
    />

    <NewItemModal
      :modal-is-show="newItemModalIsShow"
      @closeModal="toggleNewItemModal"
    />
  </v-container>
</template>

<style scoped lang="sass">

</style>
